/**
 * Javascript for Essence Ads
 *
 **/

googletag.cmd.push(function () {
	const adWidthCheck = ($ad, old) => {
		const ad_iframe = $ad.find("iframe:first");
		const ratio = 100 * (ad_iframe.width() / ad_iframe.height());
		const target_ratio = 100 * (944 / 250);
		const ad_iframe_sz = ad_iframe.width() + "x" + ad_iframe.height();
		let type = "unknown";
		if ($(window).width() - 4 <= ad_iframe.width()) {
			if (target_ratio <= ratio * 1.06 && target_ratio >= ratio * 0.94) {
				type = "flex";
			} else {
				type = "xlflex";
			}
		} else if (ad_iframe_sz !== "728x90" && ad_iframe_sz !== "320x50") {
			if (old !== "standard" || ad_iframe.height() > $ad.height()) {
				type = "standard";
			}
		}
		return type;
	};

	const adStyleSet = ($ad, type) => {
		if (type === "flex") {
			$ad.css("aspect-ratio", "auto");
			$ad.css("padding-top", "0px");
			$ad.css("padding-bottom", "0px");
		} else if (type === "xlflex") {
			$ad.css("aspect-ratio", "1920/250");
			$ad.css("padding-top", "0px");
			$ad.css("padding-bottom", "0px");
		} else if (type === "standard" || type === "unknown") {
			$ad.css("aspect-ratio", "1920/250");
			const ad_iframe = $ad.find("iframe:first");
			const padding = Math.round(($ad.height() - ad_iframe.height()) / 2);
			if (padding >= 0 && $ad.css("padding-top") === "0px") {
				$ad.css("padding-top", padding + "px");
				$ad.css("padding-bottom", padding + "px");
			} else {
				$ad.css("aspect-ratio", "auto");
				$ad.css("padding-top", "15px");
				$ad.css("padding-bottom", "15px");
			}
		}
	};

	googletag.pubads().addEventListener("slotRenderEnded", function (event) {
		let slot_id = event.slot.getSlotElementId();
		let ad_id = "#" + slot_id;
		$(ad_id).addClass("rendered");
		if (slot_id.includes("leaderboard")) {
			let type = "unknown";
			let old = "unknown";
			const started = Date.now();
			const interval = setInterval(function () {
				if (Date.now() - started > 1000) {
					adStyleSet($(ad_id), type);
					clearInterval(interval);
				} else {
					type = adWidthCheck($(ad_id), old);
				}
				if (old !== type) {
					old = type;
					adStyleSet($(ad_id), type);
					clearInterval(interval);
				}
			}, 100);
		}
	});
});
